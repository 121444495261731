<!-- 编辑租户设备型号 -->
<template>
  <el-dialog
      title="编辑设备型号"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="80%"
  >
    <div class="grid grid-cols-12 gap-3">
      <div class="border p-4 col-span-5">
        <deviceDefaultInfo :deviceObj="deviceObj"></deviceDefaultInfo>
      </div>
      <div class="col-span-1 justify-content-center align-items-center flex">
        <i class="text-4xl el-icon-right"></i>
      </div>
      <div class="border p-4 col-span-5">
        <div
            class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2"
        >
          设备展示别名
        </div>
        <el-form
            class="mt-4"
            ref="form"
            :model="customerDeviceObj"
            label-width="80px"
            size="small"
        >
          <el-form-item>
            <template #label>
              <span class="text-xs"> 厂家名称 </span>
            </template>
            <el-input v-model="customerDeviceObj.prodFactory"></el-input>
          </el-form-item>
          <el-form-item>
            <template #label>
              <span class="text-xs"> 品牌名称 </span>
            </template>
            <el-input v-model="customerDeviceObj.prodBrand"></el-input>
          </el-form-item>
          <el-form-item>
            <template #label>
              <span class="text-xs"> 设备型号 </span>
            </template>
            <el-input v-model="customerDeviceObj.prodModel"></el-input>
          </el-form-item>
          <el-form-item>
            <template #label>
              <span class="text-xs"> API支持情况 </span>
            </template>
            <el-select
                v-model="deviceFunc"
                multiple
                placeholder="请选择"
                class="w-full"
            >
              <el-option
                  v-for="item in sysDeviceFunctionalList"
                  :key="item.prop"
                  :label="item.label"
                  :value="item.prop"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tips text-xs text-gray-400 mt-2">
      <i class="el-icon-info"></i>
      别名展示规则为 租户设置 > 设备管理设置 > 系统
    </div>
    <div class="mt-6 w-full text-center">
      <el-button @click="submitDeviceModel" type="primary" class="w-80"
      >提交
      </el-button>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import deviceDefaultInfo from '../device/deviceDefaultInfo.vue'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialogVisible: false,
      deviceObj: {},
      customerDeviceObj: {},
      deviceFunc: [],
      sysDeviceFunctionalList: []
    }
  },
  computed: {
    ...mapGetters(['deviceFunctionalList'])
  },
  components: {
    deviceDefaultInfo
  },
  props: {
    type: {
      // 1.代理端全局设备型号 2.租户设备型号
      type: Number,
      default: () => {
        return 1
      }
    }
  },
  methods: {
    close () {
      this.dialogVisible = false
      this.deviceObj = {}
      this.customerDeviceObj = {}
      this.deviceFunc = []
      this.sysDeviceFunctionalList = []
    },
    async show ({ deviceObj }) {
      this.deviceObj = await this.queryDeviceModelList(deviceObj)
      this.customerDeviceObj = JSON.parse(JSON.stringify(deviceObj))
      this.handlerDeviceFuncList()
      this.handlerDeviceFunc()
      this.dialogVisible = true
    },
    /**
     * 处理API可选选项
     */
    handlerDeviceFuncList () {
      this.sysDeviceFunctionalList = []
      const funcList = this.deviceFunctionalList.map(item => item.prop)
      for (const key in this.deviceObj) {
        if (funcList.indexOf(key) > -1 && this.deviceObj[key] === 1) {
          const result = this.deviceFunctionalList.find(item => item.prop === key)
          this.sysDeviceFunctionalList.push(result)
        }
      }
    },
    /**
     * 处理已选中API
     */
    handlerDeviceFunc () {
      const funcList = this.deviceFunctionalList.map(item => item.prop)
      for (const key in this.customerDeviceObj) {
        const item = this.customerDeviceObj[key]
        if (item === 1 && funcList.indexOf(key) > -1) {
          this.deviceFunc.push(key)
        }
      }
    },
    queryDeviceModelList ({
      deviceModel,
      deviceType
    }) {
      return new Promise(resolve => {
        this.$api.sysDevice.queryDeviceModelList({
          deviceModel,
          deviceType
        }).then(res => {
          resolve(res.data[0])
        })
      })
    },
    submitDeviceModel () {
      const funcList = this.deviceFunctionalList.map(item => item.prop)
      funcList.forEach(key => {
        this.customerDeviceObj[key] = 0
      })
      this.deviceFunc.forEach(key => {
        this.customerDeviceObj[key] = 1
      })
      const successFunc = () => {
        this.$message.success('修改成功')
        this.$emit('on-submit')
        this.close()
      }
      if (this.type === 1) {
        this.$api.sysDevice.insert(this.customerDeviceObj).then(successFunc)
      } else if (this.type === 2) {
        this.$api.deviceModelJoint.insert({
          ...this.customerDeviceObj,
          customerId: this.$route.query.id
        }).then(successFunc)
      }
    }
  },
  mounted () {
  }
}
</script>
