<!--  -->
<template>
  <div>
    <blockTitle title="设备详情"></blockTitle>
    <div class="grid grid-cols-4 gap-4">
      <div class="col-span-2 bg-white p-4 border">
        <!--        <el-card shadow="never">-->
        <deviceDefaultInfo
            @editorProdName="editorProdName"
            showEditorProdName
            showProdName
            :deviceObj="deviceObj"
        ></deviceDefaultInfo>
        <!--        </el-card>-->
      </div>

      <div class="col-span-2 bg-white p-4 border">
        <!--        <el-card shadow="never">-->
        <div
            class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2"
        >
          设备客户使用情况
        </div>
        <div class="flex flex-row divide-x mt-4">
          <div class="text-xs leading-8 text-gray-600">
            <div class="mb-2">设备总数：{{ hadActivate }} 台</div>
            <div class="mb-2">已激活数量：{{ total }} 台</div>
          </div>

          <div class="chart ml-4 pl-4 text-xs">
            <div id="chart" ref="chart" class="mt-6 w-80 chart h-40"></div>
          </div>
        </div>
        <!--        </el-card>-->
      </div>
    </div>

    <!--    <el-row class="mt-4">-->
    <!--      <el-col :span="24">-->
    <!--        <el-card shadow="never">-->
    <!--          <div class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2">-->
    <!--            APK版本信息-->
    <!--            <el-button size="mini" class="ml-4" @click="showUploadApk">上传APK</el-button>-->
    <!--          </div>-->
    <!--          <AppTable ref="apkAppTable" :tableColumns="tableColumns" controller="/sysDeviceApkOta/apk" class="mt-2"-->
    <!--                    :request-params="requestParams" :defaultGetData="false">-->
    <!--            <template #operator="{row}">-->
    <!--              <el-button size="mini" type="danger" @click="deleteApk(row)">删除</el-button>-->
    <!--            </template>-->
    <!--          </AppTable>-->
    <!--        </el-card>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
    <uploadAPK
        @submit="reGetApk"
        ref="uploadAPK"
        :deviceObj="deviceObj"
    ></uploadAPK>
    <editorDeviceModelDialog
        @on-submit="editorDeviceModelOnSubmit"
        ref="editorDeviceModelDialog"
    ></editorDeviceModelDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'
import deviceDefaultInfo from '../../components/device/deviceDefaultInfo.vue'
import editorDeviceModelDialog from '../../components/client/editorDeviceModelDialogV1.vue'
import uploadAPK from 'components/device/uploadAPK'

export default {
  data () {
    return {
      deviceObj: {},
      tableColumns: [
        {
          label: '所属服务器',
          prop: 'hostName'
        },
        {
          label: '节点名称',
          prop: 'pointName'
        },
        {
          label: '所属代理商',
          prop: 'agUserName'
        },
        {
          label: '代理标识',
          prop: 'agent'
        },
        {
          label: '版本号',
          prop: 'versionName'
        },
        {
          label: '操作',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      requestParams: {
        model: ''
      },
      hadActivate: 0,
      total: 0,
      option: {}
    }
  },
  components: {
    uploadAPK,
    deviceDefaultInfo,
    editorDeviceModelDialog
  },
  computed: {
    ...mapGetters(['deviceFunctionalList']),
    isSupport () {
      const arr = []
      this.deviceFunctionalList.forEach(item => {
        if (this.deviceObj[item.prop] === 1) {
          arr.push(item.label)
        }
      })
      return arr
    }
  },
  methods: {
    getData () {
      this.$api.findAll('sysDevice', {
        deviceType: this.$route.query.deviceType,
        deviceModel: this.$route.query.deviceModel
      }).then(res => {
        this.deviceObj = res.data[0]
        this.requestParams.model = this.deviceObj.prodModel
        this.getCountCustomerDevice()
      })
    },
    showUploadApk () {
      this.$refs.uploadAPK.show()
    },
    reGetApk () {
      this.$refs.apkAppTable.reGetData()
    },
    deleteApk (item) {
      this.$api.sysDeviceApkOta.deleteBatch({ ids: item.id }).then(() => {
        this.reGetApk()
      })
    },
    getCountCustomerDevice () {
      this.$api.customerDevice.countCustomerDevice({
        deviceType: this.$route.query.deviceType,
        deviceModel: this.$route.query.deviceModel
      }).then(res => {
        this.hadActivate = res.data.hadActivate
        this.total = res.data.total
        const data = res.data.customerList.map(item => {
          return {
            value: item.num,
            name: item.customerName
          }
        })
        const chart = this.$echarts.init(this.$refs.chart)
        this.option = {
          title: {
            text: '代理使用情况',
            subtext: '',
            left: 'center'
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left'
          },
          series: [
            {
              name: '租户使用情况',
              type: 'pie',
              radius: '70%',
              top: 5,
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        chart.setOption(this.option)
      })
    },
    editorProdName (deviceObj) {
      this.$refs.editorDeviceModelDialog.show({ deviceObj })
    },
    editorDeviceModelOnSubmit () {
      this.getData()
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
