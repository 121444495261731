<!--  -->
<template>
  <el-dialog
      title="上传APK"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      @close="close"
      top="5vh"
      v-loading="isUploading"
      width="30%"
  >
    <el-upload
        v-if="!apkUrl"
        class="w-full"
        drag
        :show-file-list="false"
        :http-request="apkUploader"
        action="#"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    </el-upload>
    <div class="apk-info text-xs leading-8 text-gray-600" v-if="apkUrl">
      <div class="title text-lg font-bold mb-2">APK内容信息</div>
      <div class="item flex flex-row">
        <div class="label">产品型号:</div>
        <div class="value ml-2">
          {{ apkInfo.model }}
        </div>
      </div>
      <div class="item flex flex-row">
        <div class="label">代理名称:</div>
        <div class="value ml-2">
          {{ apkInfo.agUserName || '-' }}
        </div>
      </div>
      <div class="item flex flex-row">
        <div class="label">代理标识:</div>
        <div class="value ml-2">
          {{ apkInfo.agent }}
        </div>
      </div>
      <div class="item flex flex-row">
        <div class="label">文件标识:</div>
        <div class="value ml-2">
          {{ apkInfo.fileHex }}
        </div>
      </div>
      <div class="item flex flex-row">
        <div class="label">versionCode:</div>
        <div class="value ml-2">
          {{ apkInfo.versionCode }}
        </div>
      </div>
      <div class="item flex flex-row">
        <div class="label">版本号:</div>
        <div class="value ml-2">
          {{ apkInfo.versionName }}
        </div>
      </div>
      <el-button @click="clearInfo" size="small" class="mt-4" type="danger">
        重新上传
      </el-button>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">关闭</el-button>
      <el-button
          v-if="apkUrl"
          type="success"
          @click="submit"
          icon="el-icon-success"
      >信息无误 确定发布</el-button
      >
    </span>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less">
.w-full {
  /deep/ .el-upload {
    width: 100%;
  }

  /deep/ .el-upload-dragger {
    width: 100%;
  }
}
</style>
<script type="text/ecmascript-6">
import oss from '@utils/oss'

export default {
  data () {
    return {
      dialogVisible: false,
      apkUrl: '',
      isUploading: false,
      apkInfo: {}
    }
  },
  props: {
    deviceObj: {}
  },
  methods: {
    submit () {
      if (this.deviceObj.deviceModel !== this.apkInfo.model) {
        this.$message.error(`当前上传的APK不属于设备${this.deviceObj.deviceModel}的APK,请检查后再次上传.`)
        return false
      }
      this.$api.sysDeviceApkOta.saveApk(this.apkInfo).then(res => {
        this.$message.success('APK保存成功')
        this.$emit('submit')
        this.close()
      })
    },
    show () {
      this.dialogVisible = true
    },
    close () {
      this.dialogVisible = false
      this.clearInfo()
    },
    async apkUploader (item) {
      const { file } = item
      if (file.name.indexOf('.apk') === -1) {
        this.$message.error('请选择APK文件')
        return false
      }
      this.isUploading = true
      this.apkUrl = await oss.upload(file)
      this.readApk(this.apkUrl)
    },
    readApk (fileUrl) {
      this.$api.sysDeviceApkOta.readApkFile(fileUrl).then(res => {
        console.log(res)
        this.apkInfo = res.data
        this.isUploading = false
      })
    },
    clearInfo () {
      this.apkUrl = ''
      this.isUploading = false
      this.apkInfo = {}
    }
  },
  mounted () {
  }
}
</script>
