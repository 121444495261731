<!-- 设备基本信息 -->
<template>
  <div>
    <div
        class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2"
    >
      设备基本信息
      <div
          class="float-right cursor-pointer"
          @click="editorDevice"
          v-if="editorBtn"
      >
        <i class="el-icon-edit"></i>
        编辑
      </div>
      <div
          class="float-right cursor-pointer"
          @click="editorProdName"
          v-if="showEditorProdName"
      >
        <i class="el-icon-edit"></i>
        编辑别名
      </div>
    </div>
    <div class="dev-info flex flex-row mt-4">
      <div class="device-img">
        <img class="w-32" :src="deviceObj.images" alt=""/>
      </div>
      <div class="info text-xs leading-8 text-gray-600 ml-4 flex-1">
        <div class="flex flex-row justify-between">
          <div class="w-5/12">
            <div class="item flex flex-row">
              <div class="label">厂家名称:</div>
              <div class="value ml-2">
                {{ deviceObj.factoryName }}
              </div>
            </div>

            <div class="item flex flex-row">
              <div class="label">品牌名称:</div>
              <div class="value ml-2">
                {{ deviceObj.brandName }}
              </div>
            </div>
            <div class="item flex flex-row">
              <div class="label">设备型号:</div>
              <div class="value ml-2">
                {{ deviceObj.deviceModel }}
              </div>
            </div>

            <div class="item flex flex-row">
              <div class="label">主板方案:</div>
              <div class="value ml-2">
                {{ deviceObj.mainBoardPlan }}
              </div>
            </div>

            <div class="item flex flex-row">
              <div class="label">操作系统:</div>
              <div class="value ml-2">
                {{ deviceObj.operationSystem }}
              </div>
            </div>
          </div>

          <div class="w-5/12" v-if="showProdName">
            <div class="item flex flex-row">
              <div class="label">厂家名称（别名）:</div>
              <div class="value ml-2">
                {{ deviceObj.prodFactory }}
              </div>
            </div>

            <div class="item flex flex-row">
              <div class="label">品牌名称（别名）:</div>
              <div class="value ml-2">
                {{ deviceObj.prodBrand }}
              </div>
            </div>
            <div class="item flex flex-row">
              <div class="label">设备型号（别名）:</div>
              <div class="value ml-2">
                {{ deviceObj.prodModel }}
              </div>
            </div>
          </div>
        </div>

        <div class="item flex flex-row">
          <div class="label">API支持情况:</div>
          <div class="value ml-2 w-96">
            <el-tag
                v-for="(item, index) in isSupport"
                :key="index"
                size="small"
                class="mr-1"
            >
              {{ item }}
            </el-tag>
          </div>
        </div>
      </div>
    </div>
    <!--    <editorDeviceModelDialog ref="editorDeviceModelDialog"></editorDeviceModelDialog>-->
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'

export default {
  name: 'deviceDefaultInfo',
  data () {
    return {}
  },
  props: {
    deviceObj: {},
    editorBtn: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    showProdName: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    showEditorProdName: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  computed: {
    ...mapGetters(['deviceFunctionalList']),
    isSupport () {
      const arr = []
      this.deviceFunctionalList.forEach(item => {
        if (this.deviceObj[item.prop] === 1) {
          arr.push(item.label)
        }
      })
      return arr
    }
  },
  methods: {
    editorDevice () {
      this.$emit('editorDevice')
    },
    editorProdName () {
      this.$emit('editorProdName', this.deviceObj)
    }
  },
  mounted () {
  }
}
</script>
